/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountReviewStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  _UNKNOWN_ = "_UNKNOWN_",
}

export enum ActivatePageDiscountStatus {
  already_used = "already_used",
  not_eligible = "not_eligible",
  not_offered = "not_offered",
  success = "success",
  token_mismatch = "token_mismatch",
}

export enum AdCampaignType {
  click_to_messenger = "click_to_messenger",
  sponsored_message = "sponsored_message",
}

export enum AdStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
  PAUSED = "PAUSED",
}

export enum AdditionalAuthErrorType {
  bad_email = "bad_email",
  bad_guest_id = "bad_guest_id",
  bad_password = "bad_password",
  existing_email = "existing_email",
  existing_guest_id = "existing_guest_id",
  fb_already_connected_to_guest_id = "fb_already_connected_to_guest_id",
  invalid_credentials = "invalid_credentials",
  invalid_password_recovery_code = "invalid_password_recovery_code",
  too_many_recovery_requests = "too_many_recovery_requests",
}

export enum AdsManagerBotsListBannerType {
  common = "common",
  with_ad_subscribers = "with_ad_subscribers",
  with_comments = "with_comments",
}

export enum AiIntentActionItemType {
  block = "block",
  text = "text",
}

export enum AiIntentFilterType {
  case_insensitive_match = "case_insensitive_match",
  contains = "contains",
  default = "default",
}

export enum AssignedPromoCodeStatus {
  assigned = "assigned",
  used = "used",
}

export enum BlockContextType {
  comments = "comments",
}

export enum BlockType {
  ai = "ai",
  broadcast = "broadcast",
  flow = "flow",
  structure = "structure",
}

export enum BotLivelinessStatus {
  INSTAGRAM_NOT_LINKED = "INSTAGRAM_NOT_LINKED",
  LIVE = "LIVE",
  LIVE_WITH_DEBT = "LIVE_WITH_DEBT",
  OFF_WITHOUT_INGRESS_CONTROLLERS = "OFF_WITHOUT_INGRESS_CONTROLLERS",
  OFF_WITHOUT_PAGE = "OFF_WITHOUT_PAGE",
  OFF_WITHOUT_PERMISSIONS = "OFF_WITHOUT_PERMISSIONS",
  PAUSED = "PAUSED",
  SHOPIFY_OFF_WITHOUT_SUBSCRIPTION = "SHOPIFY_OFF_WITHOUT_SUBSCRIPTION",
  SHOPIFY_STOPPED_CAP_REACHED = "SHOPIFY_STOPPED_CAP_REACHED",
  SHOPIFY_STOPPED_CONNECTION_ISSUES = "SHOPIFY_STOPPED_CONNECTION_ISSUES",
  SHOPIFY_STOPPED_STORE_FROZEN = "SHOPIFY_STOPPED_STORE_FROZEN",
  STOPPED = "STOPPED",
}

export enum BotStatusName {
  connected = "connected",
  copying = "copying",
  draft = "draft",
  pending = "pending",
  published = "published",
}

export enum BroadcastBlockType {
  auto = "auto",
  now = "now",
  schedule = "schedule",
  sent = "sent",
  sequence = "sequence",
}

export enum BroadcastStatus {
  DRAFT = "DRAFT",
  LIVE = "LIVE",
  PAUSED = "PAUSED",
  SCHEDULED = "SCHEDULED",
  SENT = "SENT",
}

export enum BuiltinBlockStatus {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}

export enum BuiltinBlockType {
  default_message = "default_message",
  welcome_message = "welcome_message",
}

export enum BusinessVerificationStatus {
  _UNKNOWN_ = "_UNKNOWN_",
  not_verified = "not_verified",
  pending_need_more_info = "pending_need_more_info",
  pending_submission = "pending_submission",
  verified = "verified",
}

export enum CardButtonType {
  calendly_cancel = "calendly_cancel",
  calendly_join_meeting = "calendly_join_meeting",
  calendly_reschedule = "calendly_reschedule",
  calendly_schedule = "calendly_schedule",
  listButton = "listButton",
  payment = "payment",
  regular = "regular",
  shopify_order_list = "shopify_order_list",
  shopify_page = "shopify_page",
}

export enum ChargeDisplayType {
  adjustment_fee = "adjustment_fee",
  pause = "pause",
  prepayment = "prepayment",
  prepayment_delayed_debt = "prepayment_delayed_debt",
  tier_upgrade = "tier_upgrade",
}

export enum ChargingEntityType {
  dialogs = "dialogs",
  users = "users",
}

export enum ChatfuelUseCase {
  answer_questions = "answer_questions",
  generate_leads = "generate_leads",
  other = "other",
  react_to_comments = "react_to_comments",
  sell_roducts = "sell_roducts",
}

export enum ChurnSurveyAction {
  cancel = "cancel",
  discount = "discount",
  pause = "pause",
}

export enum ChurnSurveyAdviceType {
  downgrade_plan = "downgrade_plan",
  improve_bot = "improve_bot",
  pause_bot = "pause_bot",
  remove_users = "remove_users",
}

export enum CustomerActionType {
  buttons = "buttons",
  ice_breakers = "ice_breakers",
  none = "none",
  quick_replies = "quick_replies",
}

export enum DiscountStatus {
  eligible = "eligible",
  not_eligible = "not_eligible",
}

export enum DiscountType {
  cancel_pro = "cancel_pro",
  upgrade_to_pro = "upgrade_to_pro",
}

export enum DisplayVariant {
  leaveOpen = "leaveOpen",
  tenSecThenHide = "tenSecThenHide",
}

export enum EmailVerificationStatus {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}

export enum EntryPointTooltip {
  WhatsappBusinessVerificationRequired = "WhatsappBusinessVerificationRequired",
}

export enum EventType {
  custom = "custom",
  standard = "standard",
}

export enum ExternalIntegrationEntryPointType {
  facebook = "facebook",
  shopify = "shopify",
  zapier = "zapier",
}

export enum FacebookAdCampaignCategory {
  active = "active",
  all = "all",
  inactive = "inactive",
}

export enum FacebookAdCampaignStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
  IN_PROCESS = "IN_PROCESS",
  PAUSED = "PAUSED",
  WITH_ISSUES = "WITH_ISSUES",
}

export enum FacebookAdCustomerActionType {
  buttons = "buttons",
  ice_breakers = "ice_breakers",
  none = "none",
  quick_replies = "quick_replies",
}

export enum FacebookAdMediaType {
  image = "image",
  text = "text",
  video = "video",
}

export enum FacebookPagePostType {
  promoted = "promoted",
  published = "published",
  scheduled = "scheduled",
}

export enum FirstSessionAiIntentsType {
  default = "default",
  saved = "saved",
}

export enum FlowTourInitialModal {
  WelcomeMessage = "WelcomeMessage",
  WhatsappAddMetaPaymentMethod = "WhatsappAddMetaPaymentMethod",
  WhatsappBookingDemo = "WhatsappBookingDemo",
  WhatsappBusinessVerification = "WhatsappBusinessVerification",
  WhatsappConnectPhoneTutorial = "WhatsappConnectPhoneTutorial",
  WhatsappGreenBadge = "WhatsappGreenBadge",
  WhatsappReengage = "WhatsappReengage",
  WhatsappSubscribeToPhone = "WhatsappSubscribeToPhone",
  WhatsappTemplates = "WhatsappTemplates",
}

export enum Folder {
  all = "all",
  closed = "closed",
  completed = "completed",
  done = "done",
  inbox = "inbox",
}

export enum Frequency {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

export enum FromType {
  admin = "admin",
  page = "page",
  public_api = "public_api",
  user = "user",
}

export enum GoogleAuthErrorType {
  existing_email = "existing_email",
  google_api_error = "google_api_error",
  invalid_credentials = "invalid_credentials",
}

export enum GreetingDialogDisplay {
  fade = "fade",
  hide = "hide",
  show = "show",
}

export enum HubSpotIntegrationStatus {
  connected = "connected",
  none = "none",
}

export enum Industry {
  e_commerce = "e_commerce",
  education = "education",
  health_and_beauty = "health_and_beauty",
  marketing = "marketing",
  other = "other",
}

export enum InstagramConnectionProblem {
  no_access_to_direct_messages = "no_access_to_direct_messages",
  no_access_to_direct_messages_toggle = "no_access_to_direct_messages_toggle",
  not_a_business_account = "not_a_business_account",
  not_an_admin = "not_an_admin",
  not_connected_to_page = "not_connected_to_page",
  other_error = "other_error",
}

export enum InstagramPostType {
  CAROUSEL_ALBUM = "CAROUSEL_ALBUM",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum IntegrationZapierPluginConfigView {
  all = "all",
  full = "full",
  last = "last",
}

export enum IntroQuestionsKeywordsSelector {
  industry = "industry",
  niche = "niche",
  platform = "platform",
}

export enum IntroQuestionsQuestionType {
  checkbox = "checkbox",
  multiselect = "multiselect",
  phoneInput = "phoneInput",
  radiobutton = "radiobutton",
  select = "select",
  text = "text",
}

export enum JsonPluginRequestPostFormat {
  full_json_profile = "full_json_profile",
  json = "json",
  urlencoded = "urlencoded",
}

export enum JsonPluginRequestType {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
}

export enum KommoActionType {
  create_lead = "create_lead",
  create_task = "create_task",
  update_lead = "update_lead",
}

export enum KommoFieldType {
  birthday = "birthday",
  category = "category",
  checkbox = "checkbox",
  date = "date",
  date_time = "date_time",
  items = "items",
  legal_entity = "legal_entity",
  multiselect = "multiselect",
  numeric = "numeric",
  price = "price",
  radiobutton = "radiobutton",
  select = "select",
  smart_address = "smart_address",
  streetaddress = "streetaddress",
  text = "text",
  textarea = "textarea",
  tracking_data = "tracking_data",
  url = "url",
}

export enum KommoIntegrationStatus {
  connected = "connected",
  none = "none",
}

export enum LeaveReason {
  hard_to_use = "hard_to_use",
  many_technical_issues = "many_technical_issues",
  my_page_is_restricted = "my_page_is_restricted",
  other = "other",
  project_shut_down = "project_shut_down",
  temporary_cancellation = "temporary_cancellation",
  too_expensive = "too_expensive",
  wasnt_using_it_enough = "wasnt_using_it_enough",
}

export enum LiveCycleBroadcastStatus {
  enable = "enable",
  pause = "pause",
  unpause = "unpause",
}

export enum ManagedWhatsappAttachmentType {
  document = "document",
  image = "image",
  video = "video",
}

export enum MessagesTemplateCategory {
  marketing = "marketing",
  utility = "utility",
}

export enum MessagesTemplateErrorType {
  meta_upload_error = "meta_upload_error",
  string_contains_invalid_number_of_examples = "string_contains_invalid_number_of_examples",
  string_contains_too_many_attributes = "string_contains_too_many_attributes",
  string_is_not_a_valid_phone_number = "string_is_not_a_valid_phone_number",
  string_is_not_a_valid_url = "string_is_not_a_valid_url",
  string_is_too_long = "string_is_too_long",
  string_is_too_short = "string_is_too_short",
  too_many_buttons_of_type = "too_many_buttons_of_type",
  unexpected_error = "unexpected_error",
  whatsapp_business_is_restricted = "whatsapp_business_is_restricted",
  whatsapp_template_name_is_taken = "whatsapp_template_name_is_taken",
}

export enum MessagesTemplateHeaderType {
  attachment = "attachment",
  text = "text",
}

export enum OnboardingTipsStatus {
  available = "available",
  disabled = "disabled",
  done = "done",
}

export enum OnboardingTourCustomPopupId {
  WANewTemplate = "WANewTemplate",
}

export enum OnboardingTourEventType {
  change = "change",
  click = "click",
  connectWhatsapp = "connectWhatsapp",
  remove = "remove",
  restart = "restart",
}

export enum OnboardingTourHTMLElementId {
  ChooseWATemplateDialog = "ChooseWATemplateDialog",
  ChooseWATemplateDialogInputSearch = "ChooseWATemplateDialogInputSearch",
  HomeTabWhatsappAccountBlock = "HomeTabWhatsappAccountBlock",
  HomeTabWhatsappAccountBlockConnectionButton = "HomeTabWhatsappAccountBlockConnectionButton",
  NavLinkToBroadcast = "NavLinkToBroadcast",
  OpenAiPlugin = "OpenAiPlugin",
  OpenAiPluginAdditionInfoTextArea = "OpenAiPluginAdditionInfoTextArea",
  OpenAiPluginInstruction = "OpenAiPluginInstruction",
  OpenAiPluginLiveChatInstruction = "OpenAiPluginLiveChatInstruction",
  OpenAiPluginLiveChatTab = "OpenAiPluginLiveChatTab",
  PluginCloseButton = "PluginCloseButton",
  TestThisFlowButton = "TestThisFlowButton",
  TestThisFlowChatCloseButton = "TestThisFlowChatCloseButton",
  TestThisFlowChatContent = "TestThisFlowChatContent",
  TestThisFlowDialog = "TestThisFlowDialog",
  WAAttributeInput = "WAAttributeInput",
  WAEditorPluginPanel = "WAEditorPluginPanel",
  WAPhoneConnectionModal = "WAPhoneConnectionModal",
  WAReEngageEditorPanel = "WAReEngageEditorPanel",
  WAReEngageFlowBuilderWrapper = "WAReEngageFlowBuilderWrapper",
  WAReengageCreateNewButton = "WAReengageCreateNewButton",
  WAReengagePanelStartButton = "WAReengagePanelStartButton",
  WATemplateCreateAnchor = "WATemplateCreateAnchor",
  WATemplateCreateContainer = "WATemplateCreateContainer",
  WATemplateModalNextButton = "WATemplateModalNextButton",
  WATemplateTableRow = "WATemplateTableRow",
}

export enum OnboardingTourShape {
  ButtonEditView = "ButtonEditView",
  ButtonEditViewContainer = "ButtonEditViewContainer",
  CommentsAutoreplyKeywordsGroup = "CommentsAutoreplyKeywordsGroup",
  CommentsWithKeywordsContainer = "CommentsWithKeywordsContainer",
  EntryPointToggle = "EntryPointToggle",
  EntryPointToggleContainer = "EntryPointToggleContainer",
  IceBreakerTitleButtonShape = "IceBreakerTitleButtonShape",
  LikeCommentsToggle = "LikeCommentsToggle",
  LikeCommentsToggleContainer = "LikeCommentsToggleContainer",
  OpenAiPluginArrowsContainer = "OpenAiPluginArrowsContainer",
  OpenAiPromptSetUp = "OpenAiPromptSetUp",
  PrivateReplyTextArea = "PrivateReplyTextArea",
  PublicReplyTextArea = "PublicReplyTextArea",
  QuickReplyButtonViewButtonShape = "QuickReplyButtonViewButtonShape",
  QuickReplyButtonViewTextAreaShape = "QuickReplyButtonViewTextAreaShape",
  TextPluginTextArea = "TextPluginTextArea",
  WhatsappTemplatePlugin = "WhatsappTemplatePlugin",
}

export enum OrderStatusUpdateStatus {
  attempted_delivery = "attempted_delivery",
  delivered = "delivered",
  in_transit = "in_transit",
  out_for_delivery = "out_for_delivery",
}

export enum OriginalAccountType {
  email = "email",
  facebook = "facebook",
  google = "google",
  guest = "guest",
}

export enum PageArchivingStatus {
  ARCHIVED = "ARCHIVED",
  ARCHIVING = "ARCHIVING",
  UNARCHIVING = "UNARCHIVING",
}

export enum ParameterFilterValueOperator {
  attempted = "attempted",
  blocked = "blocked",
  clicked = "clicked",
  contains = "contains",
  failed = "failed",
  gt = "gt",
  is = "is",
  is_not = "is_not",
  lt = "lt",
  not_attempted = "not_attempted",
  not_blocked = "not_blocked",
  not_clicked = "not_clicked",
  not_failed = "not_failed",
  not_seen = "not_seen",
  not_sent = "not_sent",
  seen = "seen",
  sent = "sent",
  starts_with = "starts_with",
  was = "was",
  was_not = "was_not",
}

export enum ParametersOperator {
  and = "and",
  or = "or",
}

export enum ParaphraseMode {
  formal = "formal",
  friendly = "friendly",
  spellCheck = "spellCheck",
}

export enum PaymentDetailsStatus {
  active = "active",
  cancelled = "cancelled",
  to_be_cancelled = "to_be_cancelled",
  trial = "trial",
}

export enum PaywallStatus {
  accepted = "accepted",
  nonSkippable = "nonSkippable",
  none = "none",
  paymentFailed = "paymentFailed",
  paymentSucceeded = "paymentSucceeded",
  skippable = "skippable",
  skipped = "skipped",
}

export enum Period {
  annual = "annual",
  monthly = "monthly",
  semiannual = "semiannual",
}

export enum Platform {
  facebook = "facebook",
  instagram = "instagram",
  whatsapp = "whatsapp",
}

export enum PricingObjective {
  page = "page",
  workspace = "workspace",
}

export enum ProductListShopifyOrderButtonType {
  order_page = "order_page",
  product_page = "product_page",
}

export enum ProductListType {
  back_in_stock = "back_in_stock",
  order_content = "order_content",
  static = "static",
  visited_products = "visited_products",
}

export enum ProductListVisitedProductsRecommendationType {
  recommended = "recommended",
  similar = "similar",
}

export enum QuickReplyType {
  date = "date",
  datetime = "datetime",
  email = "email",
  number = "number",
  phone = "phone",
  text = "text",
}

export enum RequestedUserInfo {
  contact_email = "contact_email",
  contact_name = "contact_name",
  contact_phone = "contact_phone",
  shipping_address = "shipping_address",
}

export enum SanctionCheckStatus {
  cleared = "cleared",
  confirmed = "confirmed",
  ok = "ok",
  suspicious = "suspicious",
}

export enum SendToMessengerColor {
  blue = "blue",
  white = "white",
}

export enum SendToMessengerSize {
  large = "large",
  standard = "standard",
  xlarge = "xlarge",
}

export enum ShopifyDiscountDisplayType {
  buy_x_get_y = "buy_x_get_y",
  fixed_amount = "fixed_amount",
  percentage = "percentage",
  shipping = "shipping",
}

export enum ShopifyDiscountsCodeType {
  common = "common",
  unique = "unique",
}

export enum ShopifyDiscountsCodeValueType {
  fixed_amount = "fixed_amount",
  percentage = "percentage",
}

export enum ShopifyEventType {
  abandoned_cart = "abandoned_cart",
  order_confirmation = "order_confirmation",
  order_status_update = "order_status_update",
  product_visit = "product_visit",
}

export enum ShopifyFeatureType {
  discounts = "discounts",
  shipment_tracking = "shipment_tracking",
}

export enum ShopifySubscriptionStatus {
  active = "active",
  cancelled = "cancelled",
  cap_reached = "cap_reached",
  pending = "pending",
}

export enum SourceAboutUs {
  advertisement = "advertisement",
  articles = "articles",
  from_someone_else = "from_someone_else",
  online_courses = "online_courses",
  other = "other",
  youtube_videos = "youtube_videos",
}

export enum StatsType {
  new_and_blocked_users = "new_and_blocked_users",
  popular_blocks = "popular_blocks",
  popular_buttons = "popular_buttons",
  popular_urls = "popular_urls",
  sources = "sources",
  total_users_graph = "total_users_graph",
  unrecognized_ai_inputs = "unrecognized_ai_inputs",
  user_activity = "user_activity",
}

export enum SubscriptionStatus {
  active = "active",
  cancelled = "cancelled",
  to_be_cancelled = "to_be_cancelled",
  trial = "trial",
}

export enum SupportedDevices {
  ALL = "ALL",
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
}

export enum SyncingActionType {
  PAUSE = "PAUSE",
  START = "START",
}

export enum SyncingState {
  FAILED = "FAILED",
  NOT_STARTED = "NOT_STARTED",
  PAUSED = "PAUSED",
  SYNCING = "SYNCING",
}

export enum SystemVariableSource {
  chatfuel = "chatfuel",
  kommo = "kommo",
  shopify = "shopify",
}

export enum TemplateButtonType {
  marketing_opt_out = "marketing_opt_out",
  phone = "phone",
  quick_reply = "quick_reply",
  url = "url",
}

export enum TierSubtype {
  agency = "agency",
  premium = "premium",
  regular = "regular",
}

export enum TierType {
  agency100k = "agency100k",
  agency15k = "agency15k",
  agency45k = "agency45k",
  business = "business",
  entrepreneur = "entrepreneur",
  establishedBusiness = "establishedBusiness",
  gpt4o = "gpt4o",
  growingBusiness = "growingBusiness",
  manual = "manual",
  premium100k = "premium100k",
  premium15k = "premium15k",
  premium45k = "premium45k",
  premium500k = "premium500k",
  premium5k = "premium5k",
  smallBusiness = "smallBusiness",
  start = "start",
  startup = "startup",
  trial = "trial",
}

export enum Timezone {
  bot = "bot",
  utc = "utc",
}

export enum TokenError {
  CHATFUEL_ACCOUNT_CONFLICT = "CHATFUEL_ACCOUNT_CONFLICT",
  FB_PROFILE_CONFLICT = "FB_PROFILE_CONFLICT",
  INVALID_FB_PROFILE = "INVALID_FB_PROFILE",
  NO_CODE = "NO_CODE",
  NO_USER = "NO_USER",
}

export enum TriggeredMessageIntervalTimeUnit {
  day = "day",
  hour = "hour",
  minute = "minute",
  second = "second",
}

export enum TriggeredMessageType {
  abandoned_cart_update = "abandoned_cart_update",
  first_interaction = "first_interaction",
  last_interaction = "last_interaction",
  order_confirmation = "order_confirmation",
  order_status_update = "order_status_update",
  user_filter = "user_filter",
}

export enum UploadStatus {
  Failed = "Failed",
  InProgress = "InProgress",
  NotStarted = "NotStarted",
  Ok = "Ok",
}

export enum UserAttributeType {
  block = "block",
  compound = "compound",
  custom = "custom",
  segment = "segment",
  sequence = "sequence",
  system = "system",
  tag = "tag",
}

export enum UserInputPluginFormPropertyType {
  email = "email",
  number = "number",
  phone = "phone",
  text = "text",
}

export enum VariableSuggestType {
  abandoned_cart_template = "abandoned_cart_template",
  default = "default",
  omnibox = "omnibox",
  template = "template",
}

export enum VariablesType {
  custom = "custom",
  segment = "segment",
  sequence = "sequence",
  system = "system",
}

export enum WaMediaAttachmentDownloadingStatus {
  DOWNLOADED = "DOWNLOADED",
  DOWNLOADING = "DOWNLOADING",
  ERROR = "ERROR",
  EXPIRED = "EXPIRED",
  NONE = "NONE",
}

export enum WebviewHeightRatio {
  compact = "compact",
  full = "full",
  tall = "tall",
}

export enum WelcomeMessageMediaType {
  image = "image",
  text = "text",
  video = "video",
}

export enum WhatsappBroadcastType {
  MONTHLY = "MONTHLY",
  ONE_TIME = "ONE_TIME",
  TRIGGER = "TRIGGER",
  WEEKLY = "WEEKLY",
}

export enum WhatsappFileImportContactError {
  MissingColumns = "MissingColumns",
  PerFileLimitReached = "PerFileLimitReached",
  PerMonthLimitReached = "PerMonthLimitReached",
  PhoneNumberContainsNonDigitCharacters = "PhoneNumberContainsNonDigitCharacters",
  PhoneNumberFormatIsInvalid = "PhoneNumberFormatIsInvalid",
  PhoneNumberLengthIsIncorrect = "PhoneNumberLengthIsIncorrect",
  TagContainsUnsupportedCharacters = "TagContainsUnsupportedCharacters",
  TagLengthIsIncorrect = "TagLengthIsIncorrect",
  TooManyAttributesToMap = "TooManyAttributesToMap",
  UnknownError = "UnknownError",
}

export enum WhatsappImportUsersSessionStatus {
  error = "error",
  failed_completely = "failed_completely",
  failed_init = "failed_init",
  idle = "idle",
  none = "none",
  running = "running",
  success = "success",
}

export enum WhatsappListPlugHeaderType {
  document = "document",
  image = "image",
  text = "text",
  video = "video",
}

export enum WhatsappMigrationStatus {
  meeting_booked = "meeting_booked",
  migration_completed = "migration_completed",
  need_migration = "need_migration",
  none = "none",
  prolong_trial = "prolong_trial",
  trial_prolonged = "trial_prolonged",
}

export enum WhatsappOriginalMessageType {
  interactive_list_message = "interactive_list_message",
  interactive_reply_button = "interactive_reply_button",
  template = "template",
}

export enum WhatsappStatusEnum {
  delivered = "delivered",
  failed = "failed",
  pending = "pending",
  read = "read",
  sent = "sent",
}

export enum WhatsappTemplateButtonType {
  MARKETING_OPT_OUT = "MARKETING_OPT_OUT",
  PHONE_NUMBER = "PHONE_NUMBER",
  QUICK_REPLY = "QUICK_REPLY",
  URL = "URL",
}

export enum WhatsappTemplateCategory {
  ACCOUNT_UPDATE = "ACCOUNT_UPDATE",
  ALERT_UPDATE = "ALERT_UPDATE",
  APPOINTMENT_UPDATE = "APPOINTMENT_UPDATE",
  AUTHENTICATION = "AUTHENTICATION",
  AUTO_REPLY = "AUTO_REPLY",
  ISSUE_RESOLUTION = "ISSUE_RESOLUTION",
  MARKETING = "MARKETING",
  OTP = "OTP",
  PAYMENT_UPDATE = "PAYMENT_UPDATE",
  PERSONAL_FINANCE_UPDATE = "PERSONAL_FINANCE_UPDATE",
  RESERVATION_UPDATE = "RESERVATION_UPDATE",
  SHIPPING_UPDATE = "SHIPPING_UPDATE",
  TICKET_UPDATE = "TICKET_UPDATE",
  TRANSACTIONAL = "TRANSACTIONAL",
  TRANSPORTATION_UPDATE = "TRANSPORTATION_UPDATE",
  UTILITY = "UTILITY",
}

export enum WhatsappTemplateComponentFormat {
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  LOCATION = "LOCATION",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

export enum WhatsappTemplateComponentType {
  BODY = "BODY",
  BUTTONS = "BUTTONS",
  FOOTER = "FOOTER",
  HEADER = "HEADER",
}

export enum WhatsappTemplateParameterType {
  currency = "currency",
  date_time = "date_time",
  document = "document",
  image = "image",
  payload = "payload",
  text = "text",
  video = "video",
}

export enum WhatsappTemplateQualityScoreEnum {
  GREEN = "GREEN",
  RED = "RED",
  UNKNOWN = "UNKNOWN",
  YELLOW = "YELLOW",
}

export enum WhatsappTemplateStatus {
  APPROVED = "APPROVED",
  DELETED = "DELETED",
  DISABLED = "DISABLED",
  DRAFT = "DRAFT",
  IN_APPEAL = "IN_APPEAL",
  LIMIT_EXCEEDED = "LIMIT_EXCEEDED",
  PAUSED = "PAUSED",
  PENDING = "PENDING",
  PENDING_DELETION = "PENDING_DELETION",
  REJECTED = "REJECTED",
}

export enum WhatsappVerificationErrorType {
  incorrectCode = "incorrectCode",
  newCodeRequired = "newCodeRequired",
  tooManyRequests = "tooManyRequests",
  unrecognized = "unrecognized",
}

export enum WhiteLabelRole {
  admin = "admin",
  user = "user",
}

export enum ZapierTemplateSetType {
  entry_point = "entry_point",
  import_content = "import_content",
  send_data = "send_data",
}

export enum ZapierTemplateStatus {
  draft = "draft",
  published = "published",
}

export enum ZapierZapState {
  draft = "draft",
  off = "off",
  on = "on",
}

export enum ZapierZapStepType {
  filter = "filter",
  read = "read",
  search = "search",
  search_or_write = "search_or_write",
  write = "write",
}

export interface AbandonedCartActionInput {
  delay_in_minutes: number;
  next_block_id?: string | null;
  counter_id?: string | null;
}

export interface AbandonedCartEventConfigInput {
  actions: AbandonedCartActionInput[];
}

export interface AbandonedCartPluginConfigInput {
  reminder_type?: string | null;
  text_plugin: AbandonedCartPluginTextConfigInput;
  gallery_plugin: AbandonedCartPluginGalleryConfigInput;
  enabled?: boolean | null;
}

export interface AbandonedCartPluginGalleryConfigInput {
  button_title?: string | null;
}

export interface AbandonedCartPluginTextConfigInput {
  text?: string | null;
  button_title?: string | null;
}

export interface AbtPluginConfigInput {
  options: AbtPluginOptionInput[];
  user_filter?: SegmentationInput | null;
}

export interface AbtPluginOptionInput {
  name: string;
  percent: number;
  blocks?: BlockInput[] | null;
}

export interface AdCommentsAutoreplyEntryPointConfigInput {
  adAccount?: FacebookAdAccountInput | null;
  posts?: AdPostsInput[] | null;
  keywords_setup?: KeywordsSetupInput | null;
  already_subscribed_keywords_setup?: KeywordsSetupInput | null;
  already_subscribed_setup_enabled: boolean;
  apply_for_all_posts?: boolean | null;
}

export interface AdPostsInput {
  ad: CommentReplyAdInput;
  postIds?: string[] | null;
}

export interface AdTreeInput {
  id: string;
  name: string;
  created_time?: number | null;
  preview_image_small?: string | null;
  preview_image_big?: string | null;
  flow_id?: string | null;
  flow_title?: string | null;
  campaign_id?: string | null;
  type?: AdCampaignType | null;
  media_type: FacebookAdMediaType;
  customer_action_type: FacebookAdCustomerActionType;
  account_id?: string | null;
  page_id?: string | null;
  entry_point_bot_id?: string | null;
  adset?: FacebookAdSetInput | null;
  call_to_action_type?: string | null;
}

export interface AddAttributePluginConfigInput {
  title?: string | null;
  value?: string | null;
}

export interface AiIntentActionInput {
  random: boolean;
  items: (AiIntentActionItemInput | null)[];
}

export interface AiIntentActionItemInput {
  item_type: AiIntentActionItemType;
  blocks?: (BlockInput | null)[] | null;
  text?: string | null;
}

export interface AiIntentInput {
  id: string;
  name?: string | null;
  intent_id: string;
  lines: (string | null)[];
  action?: AiIntentActionInput | null;
  ig_action?: AiIntentActionInput | null;
  wa_action?: AiIntentActionInput | null;
  goto_block?: string[] | null;
  filter_type?: AiIntentFilterType | null;
}

export interface AssignAdminPluginConfigInput {
  admin_ids?: string[] | null;
}

export interface AudioPluginConfigInput {
  url?: string | null;
  filename?: string | null;
}

export interface AuthQueryParam {
  key: string;
  value: string;
}

export interface BillingDetailsInput {
  id?: string | null;
  company_name?: string | null;
  email?: string | null;
  tax_id?: string | null;
  street?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  country?: string | null;
}

export interface BlockInput {
  id: string;
  title: string;
  type?: BlockType | null;
  flow_id?: string | null;
  removed?: boolean | null;
  is_flow?: boolean | null;
  platform?: Platform | null;
}

export interface BlockLinksConfigInput {
  id: string;
  title: string;
  redirects?: RedirectLinkConfigInput[] | null;
  buttons?: TitleWithCounterInput[] | null;
  quick_replies?: TitleWithCounterInput[] | null;
}

export interface BotLinkEntryPointConfigInput {
  block_id?: string | null;
  referral: string;
}

export interface BotTagInput {
  name: string;
}

export interface BotTestingPostMessageInput {
  text?: string | null;
  clickedMessageId?: string | null;
  clickedObjectType?: string | null;
  clickedObjectIndex?: number | null;
  clickedObjectSecondIndex?: number | null;
  sectionRowId?: string | null;
  title?: string | null;
  description?: string | null;
}

export interface BroadcastBlockLinksConfigInput {
  id: string;
  title: string;
  buttons?: TitleWithCounterInput[] | null;
  quick_replies?: TitleWithCounterInput[] | null;
  type?: BroadcastBlockType | null;
}

export interface BroadcastLinksConfigInput {
  id: string;
  title: string;
  block?: BroadcastBlockLinksConfigInput | null;
}

export interface BroadcastOptionsInputType {
  time_period?: string | null;
  time_value?: number | null;
}

export interface BroadcastScheduleInput {
  startingTimestamp?: number | null;
  useBotTimeZone?: boolean | null;
  dayOfMonth?: number | null;
  daysOfWeek?: string | null;
}

export interface BroadcastTriggerSettingsInput {
  waitInterval?: number | null;
  waitIntervalTimeUnit?: TriggeredMessageIntervalTimeUnit | null;
  triggerType?: TriggeredMessageType | null;
  userFilter?: SegmentationInput | null;
  timeOfDay?: number | null;
  orderStatusUpdateStatus?: OrderStatusUpdateStatus | null;
}

export interface ButtonConfigInput {
  title: string;
  block_ids?: string[] | null;
}

export interface CalendlyExportAttributesInput {
  name: string;
  email: string;
}

export interface CalendlyIntegrationPluginConfigInput {
  account_id?: string | null;
  event_type_uri?: string | null;
  export_attributes?: CalendlyExportAttributesInput | null;
  booked_block_id?: string | null;
  booked_counter_id?: string | null;
  rescheduled_block_id?: string | null;
  rescheduled_counter_id?: string | null;
  cancelled_block_id?: string | null;
  cancelled_counter_id?: string | null;
  reminders?: (CalendlyReminderInput | null)[] | null;
}

export interface CalendlyReminderInput {
  before_ms: number;
  block_id?: string | null;
  counter_id?: string | null;
}

export interface CardButtonAttributeInput {
  name: string;
  value?: string | null;
}

export interface CardButtonInput {
  title?: string | null;
  block_id?: string[] | null;
  block_ids?: string[] | null;
  url?: string | null;
  webview_height_ratio?: WebviewHeightRatio | null;
  phone_number?: string | null;
  blocks?: BlockInput[] | null;
  counter_id?: string | null;
  attribute_update_list?: CardButtonAttributeInput[] | null;
  type?: CardButtonType | null;
  shopify_page?: ShopifyPageButtonConfigInput | null;
}

export interface CommentCardConfigInput {
  text: string;
}

export interface CommentReplyAdInput {
  id: string;
  name?: string | null;
  thumbnailUrl?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
}

export interface CommentsAutoreplyEntryPointConfigInput {
  posts: PostIdWithStatusInput[];
  apply_for_all_posts?: boolean | null;
  already_subscribed_setup_enabled: boolean;
  keywords_setup?: KeywordsSetupInput | null;
  already_subscribed_keywords_setup?: KeywordsSetupInput | null;
}

export interface CommentsAutoreplyRuleInput {
  id: string;
  phrases?: string[] | null;
  response?: string | null;
  title?: string | null;
  post_url?: string | null;
  blocks?: BlockInput[] | null;
}

export interface CommentsLikePluginConfigInput {
  likeComment: boolean;
}

export interface ContactCustomAttrInput {
  name: string;
  value: string;
}

export interface CustomAiPluginConditionInput {
  statId?: string | null;
  blockId?: string | null;
  title?: string | null;
  prompt?: string | null;
}

export interface CustomAiPluginConfigInput {
  title?: string | null;
  description: string;
  prompt: string;
  templateId: string;
  aiModel: string;
  userInputMaxTokens?: number | null;
  aiIntents: CustomAiPluginIntentInput[];
  aiConditions: CustomAiPluginConditionInput[];
}

export interface CustomAiPluginIntentInput {
  title: string;
  description?: string | null;
  prompt?: string | null;
}

export interface CustomAudienceToSInput {
  custom_audience_tos?: number | null;
  value_based_custom_audience_tos?: number | null;
}

export interface CustomerChatEntryPointConfigInput {
  domains?: string[] | null;
  logged_in_greeting?: string | null;
  logged_out_greeting?: string | null;
  theme_color?: string | null;
  greeting_dialog_display?: GreetingDialogDisplay | null;
  supported_devices?: SupportedDevices | null;
  greeting_dialog_delay?: number | null;
  pages_whitelist?: string[] | null;
  pages_blacklist?: string[] | null;
  block_id?: string | null;
  guest_block_id?: string | null;
  upgrade_block_id?: string | null;
}

export interface DatePickerPluginConfigInput {
  property?: string | null;
  type?: QuickReplyType | null;
  allow_skip?: boolean | null;
  date_button?: ButtonConfigInput | null;
  skip_button?: ButtonConfigInput | null;
  qr_card_id?: string | null;
}

export interface DocumentPluginConfigInput {
  url?: string | null;
  filename?: string | null;
}

export interface EmailSignInRequest {
  email: string;
  password: string;
}

export interface EmailSignUpRequest {
  email: string;
  password: string;
  query: AuthQueryParam[];
}

export interface EntryPointFacebookAdCampaignInput {
  id: string;
  name: string;
  ads?: EntryPointFacebookAdInput[] | null;
}

export interface EntryPointFacebookAdInput {
  id: string;
  name: string;
  campaign_id?: string | null;
  account_id?: string | null;
  preview_image_small?: string | null;
  type?: AdCampaignType | null;
  sync: boolean;
  last_synced_date?: number | null;
  call_to_action_type?: string | null;
}

export interface EntryPointInstagramAdCampaignInput {
  id: string;
  name: string;
  ads?: EntryPointInstagramAdInput[] | null;
}

export interface EntryPointInstagramAdInput {
  id: string;
  name: string;
  campaign_id?: string | null;
  account_id?: string | null;
  preview_image_small?: string | null;
  type?: AdCampaignType | null;
  sync: boolean;
  last_synced_date?: number | null;
  call_to_action_type?: string | null;
}

export interface ExportParams {
  fields?: ExportParamsField[] | null;
  desc?: boolean | null;
  sortBy?: string | null;
}

export interface ExportParamsField {
  name: string;
  type: string;
}

export interface ExportViaZapierPluginConfigInput {
  name?: string | null;
}

export interface ExternalIntegrationEntryPointConfigInput {
  integration_type?: ExternalIntegrationEntryPointType | null;
}

export interface FacebookAdAccountInput {
  id: string;
  name: string;
  tos_accepted?: CustomAudienceToSInput | null;
}

export interface FacebookAdInput {
  id: string;
  name: string;
  status: AdStatus;
  customer_action_type: FacebookAdCustomerActionType;
  call_to_action_type?: string | null;
  media_type: FacebookAdMediaType;
  campaign_id?: string | null;
  creative_id?: string | null;
  quick_replies?: FacebookAdsQuickReplyPluginConfigInput | null;
  buttons?: CardButtonInput[] | null;
}

export interface FacebookAdSetInput {
  id: string;
}

export interface FacebookAdsEntryPointConfigInput {
  campaigns: EntryPointFacebookAdCampaignInput[];
  greeting?: FacebookAdsGreetingInput | null;
  welcomeMessageType?: WelcomeMessageMediaType | null;
  customer_action_type?: CustomerActionType | null;
}

export interface FacebookAdsGreetingInput {
  type: WelcomeMessageMediaType;
}

export interface FacebookAdsQuickReplyPluginConfigInput {
  buttons?: CardButtonInput[] | null;
  property?: string | null;
  process_text_by_ai?: boolean | null;
}

export interface FacebookAnalyticsPluginConfigInput {
  eventId?: string | null;
  eventType?: EventType | null;
}

export interface FacebookPagePostCursorInput {
  type: FacebookPagePostType;
  after?: string | null;
}

export interface FacebookShopProductInput {
  id: string;
  name: string;
  description?: string | null;
  image_url?: string | null;
  url?: string | null;
}

export interface FacebookShopsEntryPointConfigInput {
  products?: FacebookShopProductInput[] | null;
  apply_for_all_products?: boolean | null;
  keywords_setup?: KeywordsSetupInput | null;
  already_subscribed_setup_enabled?: boolean | null;
  already_subscribed_keywords_setup?: KeywordsSetupInput | null;
}

export interface FacebookSuggestButtonConfigInput {
  title?: string | null;
  block_ids?: string[] | null;
  facebook_suggest?: boolean | null;
}

export interface FieldMapping {
  index: number;
  attributeName: string;
}

export interface FlowBlockInput {
  title?: string | null;
  subtype?: string | null;
  cards?: FlowCardInput[] | null;
  position_in_flow?: FlowPositionInput | null;
}

export interface FlowBlockLinksConfigInput {
  id: string;
  title: string;
  counter_id: string;
  card_id?: string | null;
}

export interface FlowBlockToCloneInput {
  id: string;
  position?: FlowPositionInput | null;
}

export interface FlowBuilderLiveChatPluginConfigInput {
  dynamic_menu_title?: string | null;
  timeout_config?: TimeoutConfigInput | null;
  button_text?: string | null;
  send_admin_notifications?: boolean | null;
  stop_message?: string | null;
  subtitle?: string | null;
  title?: string | null;
  image_url?: string | null;
}

export interface FlowCardConfigWrapperInput {
  sendToMessengerConfig?: SendToMessengerEntryPointConfigInput | null;
  persistentMenuConfig?: PersistentMenuEntryPointConfigInput | null;
  botLinkConfig?: BotLinkEntryPointConfigInput | null;
  customerChatConfig?: CustomerChatEntryPointConfigInput | null;
  shopifyCustomerChatConfig?: ShopifyCustomerChatEntryPointConfigInput | null;
  commentsAutoreplyConfig?: CommentsAutoreplyEntryPointConfigInput | null;
  adCommentsAutoreplyEntryPointConfig?: AdCommentsAutoreplyEntryPointConfigInput | null;
  facebookAdsConfig?: FacebookAdsEntryPointConfigInput | null;
  facebookShopsEntryPointConfig?: FacebookShopsEntryPointConfigInput | null;
  externalIntegrationEntryPointConfig?: ExternalIntegrationEntryPointConfigInput | null;
  instagramAdsConfig?: InstagramAdsEntryPointConfigInput | null;
  popupEntryPointConfig?: PopupEntryPointConfigInput | null;
  shopifyEventEntryPointConfig?: ShopifyEventEntryPointConfigInput | null;
  shopifyBackInStockEntryPointConfig?: ShopifyBackInStockEntryPointConfigInput | null;
  zapierImportContentPluginConfig?: IntegrationZapierPluginConfigInput | null;
  zapierEventTriggerPluginConfig?: ExportViaZapierPluginConfigInput | null;
  instagramCommentsAutoreplyConfig?: InstagramCommentsAutoreplyEntryPointConfigInput | null;
  instagramStoryReplyEntryPointConfig?: InstagramStoryReplyEntryPointConfigInput | null;
  instagramDirectEntryPointConfig?: InstagramDirectEntryPointConfigInput | null;
  instagramLinkEntryPointConfig?: InstagramLinkEntryPointConfigInput | null;
  quickReplyPluginConfig?: QuickReplyPluginConfigInput | null;
  whatsappWidgetEntryPointConfig?: WhatsappWidgetEntryPointConfigInput | null;
  whatsappPopupEntryPointConfig?: WhatsappPopupEntryPointConfigInput | null;
  commentPluginConfig?: CommentCardConfigInput | null;
  whatsappDirectEntryPointConfig?: WhatsappDirectEntryPointConfigInput | null;
}

export interface FlowCardInput {
  plugin_id: string;
  forced?: boolean | null;
  enabled?: boolean | null;
  configWrapper?: FlowCardConfigWrapperInput | null;
}

export interface FlowFollowUpInput {
  id: string;
  text: string;
  delay_ms: number;
}

export interface FlowGroupInput {
  id: string;
  title?: string | null;
  collapsed?: boolean | null;
  position?: number | null;
}

export interface FlowInput {
  id: string;
  title?: string | null;
  position?: number | null;
  collapsed?: boolean | null;
}

export interface FlowLinksConfigInput {
  id: string;
  title: string;
  blocks: FlowBlockLinksConfigInput[];
}

export interface FlowPositionInput {
  x?: number | null;
  y?: number | null;
  scale?: number | null;
}

export interface FlowSharingInput {
  sharing_enabled?: boolean | null;
  allow_cloning?: boolean | null;
}

export interface GoToBlockPluginActionInput {
  random?: boolean | null;
  items?: GoToBlockPluginActionItemInput[] | null;
}

export interface GoToBlockPluginActionItemInput {
  item_type: string;
  blocks?: string[] | null;
  text?: string | null;
}

export interface GoToBlockPluginConfigInput {
  user_filter?: SegmentationInput | null;
  action?: GoToBlockPluginActionInput | null;
  valid?: boolean | null;
}

export interface GoogleSheetPluginConfigInput {
  spreadsheet_id?: string | null;
  spreadsheet_title?: string | null;
  owner_id?: string | null;
  user_attributes?: string[] | null;
  use_all_attributes?: boolean | null;
  timezone?: Timezone | null;
}

export interface GuestSignUpRequest {
  guestId: string;
  query: AuthQueryParam[];
}

export interface HandoverProtocolConfigInput {
  application_id?: string | null;
  timeout_in_seconds?: number | null;
  stop_words?: (string | null)[] | null;
}

export interface IceBreakerInput {
  title: string;
  counter_id: string;
  block_id?: string | null;
}

export interface InboundLinksEntryPointConfigInput {
  flows: FlowLinksConfigInput[];
  blocks: BlockLinksConfigInput[];
  broadcasts: BroadcastLinksConfigInput[];
  last_refresh_date?: string | null;
}

export interface InstagramAdsEntryPointConfigInput {
  campaigns: EntryPointInstagramAdCampaignInput[];
  greeting?: InstagramAdsGreetingInput | null;
  welcomeMessageType?: WelcomeMessageMediaType | null;
  customer_action_type?: CustomerActionType | null;
}

export interface InstagramAdsGreetingInput {
  type: WelcomeMessageMediaType;
}

export interface InstagramCommentsAutoreplyEntryPointConfigInput {
  posts: InstagramPostInput[];
  apply_for_all_posts: boolean;
  already_subscribed_setup_enabled: boolean;
  keywords_setup?: KeywordsSetupInput | null;
  already_subscribed_keywords_setup?: KeywordsSetupInput | null;
}

export interface InstagramDirectEntryPointConfigInput {
  block_id?: string | null;
  ice_breakers?: IceBreakerInput[] | null;
  fill_ib_with_ig_data?: boolean | null;
  keywords_setup?: KeywordsSetupInput | null;
}

export interface InstagramLinkEntryPointConfigInput {
  block_id?: string | null;
  referral: string;
}

export interface InstagramMediaSharePluginConfigInput {
  media_id?: string | null;
  media_url?: string | null;
  media_type?: InstagramPostType | null;
  permalink?: string | null;
}

export interface InstagramPostInput {
  id: string;
  ig_id: string;
  media_type?: InstagramPostType | null;
  permalink?: string | null;
  media_url?: string | null;
  caption?: string | null;
  timestamp?: string | null;
  thumbnail_url?: string | null;
  flow_id?: string | null;
  flow_title?: string | null;
}

export interface InstagramStoryMentionEntryPointConfigInput {
  block_id?: string | null;
}

export interface InstagramStoryReplyEntryPointConfigInput {
  apply_for_all_stories: boolean;
  already_subscribed_setup_enabled: boolean;
  keywords_setup?: KeywordsSetupInput | null;
  already_subscribed_keywords_setup?: KeywordsSetupInput | null;
}

export interface IntegrationZapierPluginConfigInput {
  view?: IntegrationZapierPluginConfigView | null;
}

export interface IntroQuestionData {
  code: string;
  value: string[];
}

export interface IntroQuestionsPayload {
  schemeId: string;
  variables: IntroQuestionData[];
}

export interface JsonPathToAttrInput {
  attr: string;
  path: string;
}

export interface JsonPluginConfigInput {
  url: string;
  method: JsonPluginRequestType;
  show_error_messages?: boolean | null;
  headers?: JsonPluginJsonPluginHeaderInput[] | null;
  body?: string | null;
  post_format?: JsonPluginRequestPostFormat | null;
  use_custom_response?: boolean | null;
  custom_response_config?: JsonPathToAttrInput[] | null;
}

export interface JsonPluginJsonPluginHeaderInput {
  key: string;
  value: string;
}

export interface KeywordsSetupInput {
  intents?: AiIntentInput[] | null;
  default_block_id?: string | null;
}

export interface KommoFieldInput {
  attribute?: string | null;
  fieldId?: number | null;
  fieldName?: string | null;
  fieldType?: string | null;
}

export interface KommoPluginConfigInput {
  actionType: KommoActionType;
  pipelineId?: number | null;
  pipelineName?: string | null;
  statusId?: number | null;
  statusName?: string | null;
  assigneeId?: number | null;
  assigneeName?: string | null;
  whatsappName?: string | null;
  whatsappPhone?: string | null;
  deadline_ms?: number | null;
  text?: string | null;
  customFields?: KommoFieldInput[] | null;
}

export interface LocalizationInput {
  key: string;
  value: LocalizationValueInput;
}

export interface LocalizationValueInput {
  default?: string | null;
}

export interface ManagedWhatsappAttachmentInput {
  id?: string | null;
  type: ManagedWhatsappAttachmentType;
  url: string;
  filename: string;
}

export interface ManagedWhatsappTemplateActionsInput {
  buttonRedirects?: ManagedWhatsappTemplateButtonRedirectInput[] | null;
}

export interface ManagedWhatsappTemplateButtonRedirectInput {
  counterId: string;
  blockId: string;
}

export interface MessagesTemplateAttributeExampleInput {
  name: string;
  value: string;
}

export interface MessagesTemplateBodyInput {
  text?: string | null;
  attribute_examples?: MessagesTemplateAttributeExampleInput[] | null;
}

export interface MessagesTemplateButtonInput {
  text: string;
  type: TemplateButtonType;
  block_id?: string | null;
  counter_id?: string | null;
  phone_number?: string | null;
  url?: string | null;
  attribute_examples: MessagesTemplateAttributeExampleInput[];
}

export interface MessagesTemplateContentInput {
  header: MessagesTemplateHeaderInput;
  body: MessagesTemplateBodyInput;
  footer: MessagesTemplateFooterInput;
  buttons: MessagesTemplateButtonInput[];
}

export interface MessagesTemplateFooterInput {
  text?: string | null;
}

export interface MessagesTemplateHeaderInput {
  type?: MessagesTemplateHeaderType | null;
  text: string;
  attachment?: ManagedWhatsappAttachmentInput | null;
  attribute_examples: MessagesTemplateAttributeExampleInput[];
}

export interface MessagesTemplateInput {
  name: string;
  category?: MessagesTemplateCategory | null;
  language?: string | null;
  content: MessagesTemplateContentInput;
}

export interface NTimeNotificationPluginConfigInput {
  title?: string | null;
  topic_id?: string | null;
  image_url?: string | null;
  block_id?: string | null;
  frequency?: Frequency | null;
}

export interface OpenAiPluginConfigInput {
  intents: OpenAiPluginIntentInput[];
  prompt: string;
  liveChatPrompt?: string | null;
  saveToVariable?: string | null;
  goodAnswerNextBlock?: string | null;
  poorAnswerNextBlock?: string | null;
  liveChatAnswerNextBlock?: string | null;
  messagesHistory?: number | null;
}

export interface OpenAiPluginIntentInput {
  key: string;
  value: string;
}

export interface OrderConfirmationActionInput {
  delay_in_seconds: number;
  next_block_id?: string | null;
  counter_id?: string | null;
}

export interface OrderConfirmationEventConfigInput {
  actions: OrderConfirmationActionInput[];
}

export interface OrderReceiptPluginConfigInput {
  integration_type?: ExternalIntegrationEntryPointType | null;
  shopify_order?: ShopifyOrderInput | null;
}

export interface OrderStatusUpdateActionInput {
  delay_in_seconds: number;
  next_block_id?: string | null;
  counter_id?: string | null;
}

export interface OrderStatusUpdateEventConfigInput {
  actions: OrderStatusUpdateActionInput[];
}

export interface PaymentsConfigInput {
  terms_of_service_url?: string | null;
  merchant_emails?: string[] | null;
}

export interface PermissionInput {
  permission: string;
}

export interface PersistentMenuEntryPointConfigInput {
  language?: string | null;
  localized_items: (PersistentMenuLocaleItemInput | null)[];
}

export interface PersistentMenuItemConfigInput {
  title: string;
  block_ids?: string[] | null;
  url?: string | null;
  webview_height_ratio?: WebviewHeightRatio | null;
  counter_id?: string | null;
}

export interface PersistentMenuLocaleItemInput {
  language: string;
  user_input_disabled?: boolean | null;
  items: (PersistentMenuItemConfigInput | null)[];
}

export interface PopupEntryPointConfigInput {
  integration_type?: ExternalIntegrationEntryPointType | null;
  block_id?: string | null;
  separate_response_for_existing_users: boolean;
  existing_user_block_id?: string | null;
  shopify_popup?: ShopifyIntegrationPopupOptionsInput | null;
}

export interface PostIdWithStatusInput {
  id: string;
  message?: string | null;
  full_picture?: string | null;
  permalink_url?: string | null;
}

export interface PremiumAccountInput {
  id: string;
  billingDetails?: PremiumBillingDetailsInput | null;
}

export interface PremiumBillingDetailsInput {
  company_name: string;
  email: string;
  tax_id: string;
  street: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
}

export interface ProductListBackInStockConfigInput {
  integration_type?: ExternalIntegrationEntryPointType | null;
  description?: string | null;
  buttons: ProductListShopifyOrderButtonConfigInput[];
}

export interface ProductListItemConfigInput {
  integration_type?: ExternalIntegrationEntryPointType | null;
  shopify_product?: ProductListShopifyItemConfigInput | null;
}

export interface ProductListOrderContentConfigInput {
  integration_type?: ExternalIntegrationEntryPointType | null;
  shopify_order?: ProductListShopifyOrderContentConfigInput | null;
}

export interface ProductListPluginConfigInput {
  list_type: ProductListType;
  products?: ProductListItemConfigInput[] | null;
  order?: ProductListOrderContentConfigInput | null;
  visited_products?: ProductListVisitedProductsConfigInput | null;
  back_in_stock?: ProductListBackInStockConfigInput | null;
}

export interface ProductListShopifyItemConfigInput {
  product_id?: string | null;
  variant_id?: string | null;
  buttons: ProductListShopifyOrderButtonConfigInput[];
}

export interface ProductListShopifyOrderButtonConfigInput {
  type?: ProductListShopifyOrderButtonType | null;
  title?: string | null;
  counter_id?: string | null;
  block_id?: (string | null)[] | null;
}

export interface ProductListShopifyOrderContentConfigInput {
  order_id?: string | null;
  fulfillment_id?: string | null;
  buttons: ProductListShopifyOrderButtonConfigInput[];
}

export interface ProductListVisitedProductsConfigInput {
  recommendation_type?: ProductListVisitedProductsRecommendationType | null;
  buttons: ProductListShopifyOrderButtonConfigInput[];
}

export interface ProductVisitActionInput {
  delay_in_minutes: number;
  next_block_id?: string | null;
  counter_id?: string | null;
}

export interface ProductVisitEventConfigInput {
  actions: ProductVisitActionInput[];
}

export interface QuickReplyErrorMessagesInput {
  text?: string | null;
  number?: string | null;
  phone?: string | null;
  email?: string | null;
  date?: string | null;
  datetime?: string | null;
}

export interface QuickReplyPluginConfigInput {
  process_text_by_ai?: boolean | null;
  type?: QuickReplyType | null;
  allow_skip?: boolean | null;
  property?: string | null;
  phone?: FacebookSuggestButtonConfigInput | null;
  email?: FacebookSuggestButtonConfigInput | null;
  date_button?: ButtonConfigInput | null;
  skip_button?: ButtonConfigInput | null;
  buttons?: CardButtonInput[] | null;
  error_messages?: QuickReplyErrorMessagesInput | null;
  input_timeout_millis?: number | null;
  follow_ups?: FlowFollowUpInput[] | null;
}

export interface RedirectLinkConfigInput {
  counter_id: string;
  card_id?: string | null;
}

export interface RemoveAttributePluginConfigInput {
  title?: string | null;
}

export interface RequestDemoInput {
  clid?: string | null;
  email: string;
  name: string;
  industry: Industry;
  industry_other?: string | null;
  company?: string | null;
  phone?: string | null;
  use_case?: ChatfuelUseCase | null;
  use_case_other?: string | null;
  source?: SourceAboutUs | null;
  source_other?: string | null;
}

export interface SegmentationInput {
  operation: ParametersOperator;
  parameters?: (SegmentationParameterInput | null)[] | null;
  valid?: boolean | null;
}

export interface SegmentationParameterInput {
  type?: UserAttributeType | null;
  name?: string | null;
  operation?: ParameterFilterValueOperator | null;
  values?: (string | null)[] | null;
  sub_filter?: SegmentationInput | null;
}

export interface SendEmailPluginConfigInput {
  body?: string | null;
  emails?: string[] | null;
  title?: string | null;
}

export interface SendToMessengerEntryPointConfigInput {
  domains?: string[] | null;
  cta_text?: string | null;
  color?: SendToMessengerColor | null;
  size?: SendToMessengerSize | null;
  block_id?: string | null;
}

export interface SetupAttributePluginConfigInput {
  items?: SetupAttributePluginItemInput[] | null;
}

export interface SetupAttributePluginItemInput {
  variable_name?: string | null;
  value?: string | null;
}

export interface ShopifyBackInStockEntryPointConfigInput {
  title?: string | null;
  description: string;
  button_text: string;
  button_size: SendToMessengerSize;
  button_color: SendToMessengerColor;
  on_subscribe_block_id?: string | null;
  on_back_in_stock_block_id?: string | null;
  on_back_in_stock_counter_id?: string | null;
  notify_unreachable: boolean;
  on_back_in_stock_unreachable_block_id?: string | null;
  on_back_in_stock_unreachable_counter_id?: string | null;
}

export interface ShopifyCustomerChatEntryPointConfigInput {
  domains?: string[] | null;
  logged_in_greeting?: string | null;
  logged_out_greeting?: string | null;
  integration_type?: ExternalIntegrationEntryPointType | null;
  theme_color?: string | null;
  greeting_dialog_display?: GreetingDialogDisplay | null;
  supported_devices?: SupportedDevices | null;
  greeting_dialog_delay?: number | null;
  pages_whitelist?: string[] | null;
  pages_blacklist?: string[] | null;
  block_id?: string | null;
  guest_block_id?: string | null;
  upgrade_block_id?: string | null;
}

export interface ShopifyDiscountsCodeInput {
  code: string;
  discount_type: ShopifyDiscountsCodeType;
  value: number;
  value_type: ShopifyDiscountsCodeValueType;
  usage_limit?: number | null;
  once_per_customer?: boolean | null;
  start_date: string;
  end_date?: string | null;
  display_type?: ShopifyDiscountDisplayType | null;
}

export interface ShopifyDiscountsCodeUpdateInput {
  id: string;
  code: string;
  discount_type: ShopifyDiscountsCodeType;
  price_rule_id: string;
  value: number;
  value_type: ShopifyDiscountsCodeValueType;
  usage_limit?: number | null;
  once_per_customer?: boolean | null;
  start_date: string;
  end_date?: string | null;
  display_type?: ShopifyDiscountDisplayType | null;
}

export interface ShopifyEventConfigInput {
  type: ShopifyEventType;
  abandoned_cart?: AbandonedCartEventConfigInput | null;
  order_confirmation?: OrderConfirmationEventConfigInput | null;
  order_status_update?: OrderStatusUpdateEventConfigInput | null;
  product_visit?: ProductVisitEventConfigInput | null;
}

export interface ShopifyEventEntryPointConfigInput {
  events: ShopifyEventConfigInput[];
}

export interface ShopifyIntegrationPopupOptionsInput {
  enabled: boolean;
  title: string;
  description: string;
  button_text: string;
  color: SendToMessengerColor;
  size: SendToMessengerSize;
  trigger?: ShopifyIntegrationPopupTriggerInput | null;
  block_ids: string[];
  included_pages: string[];
  excluded_urls: string[];
}

export interface ShopifyIntegrationPopupTriggerInput {
  type?: string | null;
  idle_timeout_seconds?: number | null;
}

export interface ShopifyOrderInput {
  order_id: string;
}

export interface ShopifyPageButtonConfigInput {
  path?: string | null;
  discount?: ShopifyPageButtonDiscountConfigInput | null;
}

export interface ShopifyPageButtonDiscountConfigInput {
  type?: ShopifyDiscountsCodeType | null;
  code?: string | null;
  price_rule_id?: string | null;
  shop_id?: string | null;
}

export interface ShopifyShopPopupInput {
  title: string;
  description: string;
  button_text: string;
  trigger: ShopifyShopPopupTriggerInput;
  blocks?: BlockInput[] | null;
  excluded_urls?: string[] | null;
  enabled?: boolean | null;
}

export interface ShopifyShopPopupTriggerInput {
  type: string;
  idle_timeout_seconds?: number | null;
}

export interface SplitTrafficPluginConfigInput {
  random?: boolean | null;
  variants?: SplitTrafficPluginVariantInput[] | null;
}

export interface SplitTrafficPluginVariantInput {
  percent: number;
  block_id?: string | null;
}

export interface StartBotTestingInput {
  refId?: string | null;
  platform?: Platform | null;
  shared?: boolean | null;
  attributesToSet?: UserAttributesInput | null;
  botId?: string | null;
}

export interface TimeoutConfigInput {
  time_value?: number | null;
  time_period?: string | null;
}

export interface TitleWithCounterInput {
  title: string;
  counter_id: string;
  card_id?: string | null;
}

export interface TypingPluginConfigInput {
  timeout_milliseconds?: number | null;
}

export interface UpdateBlockData {
  broadcast_options?: BroadcastOptionsInputType | null;
  title?: string | null;
  position?: number | null;
  group_id?: string | null;
}

export interface UpdateBlocksGroup {
  id: string;
  position?: number | null;
  title?: string | null;
  with_stats?: boolean | null;
  sequence?: boolean | null;
  collapsed?: boolean | null;
}

export interface UpdateBoUserVariable {
  name: string;
  value: string;
}

export interface UpdateBotUserInput {
  update?: (UpdateBoUserVariable | null)[] | null;
  delete?: (string | null)[] | null;
}

export interface UpdateWhatsappBroadcastInput {
  title?: string | null;
  userFilter?: SegmentationInput | null;
  triggerSettings?: BroadcastTriggerSettingsInput | null;
  schedule?: BroadcastScheduleInput | null;
}

export interface UserAttributesInput {
  device?: string | null;
  iq_locale?: string | null;
  account_type?: OriginalAccountType | null;
  is_show_first_session?: boolean | null;
}

export interface UsersAttribute {
  name: string;
  value: string;
}

export interface VideoPluginConfigInput {
  url?: string | null;
  filename?: string | null;
}

export interface WhatsappBotLinkEntryPointConfigInput {
  prefilledMessage?: string | null;
}

export interface WhatsappDirectEntryPointConfigInput {
  block_id?: string | null;
}

export interface WhatsappImportUsersSessionPayload {
  attributesMappings: FieldMapping[];
  tagsColumnIndex?: number | null;
  csvDelimiter: string;
  commonAttributes: UsersAttribute[];
  commonTagNames: string[];
}

export interface WhatsappListPlugSectionInput {
  title?: string | null;
  rows: WhatsappListPlugSectionRowInput[];
}

export interface WhatsappListPlugSectionRowInput {
  block_id?: string[] | null;
  counterId?: string | null;
  title: string;
  description?: string | null;
  type?: string | null;
}

export interface WhatsappListPluginBodyInput {
  text: string;
}

export interface WhatsappListPluginConfigInput {
  listHeader?: WhatsappListPluginHeaderInput | null;
  listBody?: WhatsappListPluginBodyInput | null;
  listFooter?: WhatsappListPluginFooterInput | null;
  listButtonTitle?: string | null;
  listSections?: WhatsappListPlugSectionInput[] | null;
}

export interface WhatsappListPluginFooterInput {
  text: string;
}

export interface WhatsappListPluginHeaderFileInput {
  link: string;
  caption?: string | null;
  filename?: string | null;
}

export interface WhatsappListPluginHeaderInput {
  type: WhatsappListPlugHeaderType;
  file?: WhatsappListPluginHeaderFileInput | null;
  text?: string | null;
}

export interface WhatsappLocationPluginConfigInput {
  longitude?: string | null;
  latitude?: string | null;
  name?: string | null;
  address?: string | null;
}

export interface WhatsappMediaObjectInput {
  id?: string | null;
  link?: string | null;
  caption?: string | null;
  filename?: string | null;
}

export interface WhatsappMessagingSettingsInput {
  whatsappBusinessAccountId?: string | null;
  phoneId?: string | null;
}

export interface WhatsappPluginConfigTemplateInput {
  id: string;
  name: string;
  language: string;
  category: WhatsappTemplateCategory;
  status: WhatsappTemplateStatus;
  components: WhatsappTemplateComponentInput[];
  quality_score?: WhatsappTemplateQualityScoreInput | null;
}

export interface WhatsappPopupEntryPointConfigInput {
  header?: string | null;
  text?: string | null;
  image?: string | null;
  buttonText: string;
  prefilledMessage: string;
  delay_s: number;
}

export interface WhatsappTemplateButtonInput {
  type?: WhatsappTemplateButtonType | null;
  text: string;
  url?: string | null;
}

export interface WhatsappTemplateComponentInput {
  format?: WhatsappTemplateComponentFormat | null;
  text?: string | null;
  type?: WhatsappTemplateComponentType | null;
  buttons?: WhatsappTemplateButtonInput[] | null;
}

export interface WhatsappTemplateCurrencyInput {
  fallback_value: string;
  code: string;
  amount_1000: number;
}

export interface WhatsappTemplateDatetimeInput {
  fallback_value: string;
}

export interface WhatsappTemplateIndexedParameterInput {
  index: number;
  parameter: WhatsappTemplateParameterInput;
}

export interface WhatsappTemplateIndexedReplyButtonInput {
  index: number;
  button: WhatsappTemplateReplyButtonInput;
}

export interface WhatsappTemplateParameterInput {
  type: WhatsappTemplateParameterType;
  text?: string | null;
  currency?: WhatsappTemplateCurrencyInput | null;
  date_time?: WhatsappTemplateDatetimeInput | null;
  image?: WhatsappMediaObjectInput | null;
  document?: WhatsappMediaObjectInput | null;
  video?: WhatsappMediaObjectInput | null;
  payload?: string | null;
}

export interface WhatsappTemplatePluginConfigInput {
  whatsappBusinessAccountId?: string | null;
  template?: WhatsappPluginConfigTemplateInput | null;
  headerParameter?: WhatsappTemplateParameterInput | null;
  bodyParameters?: WhatsappTemplateParameterInput[] | null;
  urlButtonParameter?: WhatsappTemplateIndexedParameterInput | null;
  replyButtons?: WhatsappTemplateIndexedReplyButtonInput[] | null;
  managedTemplateActions?: ManagedWhatsappTemplateActionsInput | null;
  managedTemplateId?: string | null;
}

export interface WhatsappTemplateQualityScoreInput {
  score: WhatsappTemplateQualityScoreEnum;
}

export interface WhatsappTemplateReplyButtonInput {
  title: string;
  blockId?: string | null;
  counterId: string;
}

export interface WhatsappWidgetEntryPointConfigInput {
  prefilledMessage?: string | null;
  welcomeMessageDelay?: number | null;
  welcomeMessage?: string | null;
  displayVariant?: DisplayVariant | null;
}

export interface WhiteLabelPermissionsInput {
  inbox: PermissionInput;
  people: PermissionInput;
  broadcasting: PermissionInput;
  analyze: PermissionInput;
}

export interface ZapierAuthParams {
  botId: string;
  clientId: string;
  redirectUri: string;
  state: string;
  scopes: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
