const seoStrings = {
  title: 'Chatfuel dashboard',
  description:
    'Chatfuel is the leading bot platform for creating AI chatbots for Facebook. Learn how to create a Facebook Messenger bot quickly and easily - no coding required.',
  ogTitle: 'Create AI Chat Bot for Facebook',
};

module.exports = {
  seoStrings,
};
